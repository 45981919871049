export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const AUTH_LOGIN_CLEAR = 'AUTH_LOGIN_CLEAR';

export const EDIT_START = 'EDIT_START';
export const EDIT_STOP = 'EDIT_STOP';
export const CRITERIA_CHANGE = 'CRITERIA_CHANGE';

export const DEQUEUE_GLOBAL_MESSAGE = 'DEQUEUE_GLOBAL_MESSAGE';
export const ENQUEUE_GLOBAL_MESSAGE = 'ENQUEUE_GLOBAL_MESSAGE';

export const API_FETCH_RQ = 'API_FETCH_RQ';
export const API_FETCH_RCV = 'API_FETCH_RCV';
export const API_UPDATE_RQ = 'API_UPDATE_RQ';
export const API_UPDATE_RCV = 'API_UPDATE_RCV';
