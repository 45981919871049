/*
 * Created by Kevin on Feb 2022.
 */


import PropTypes from "prop-types";
import {makeStyles, MenuItem, Popover, Typography} from "@material-ui/core";
import {ExitToAppRounded as SignOutIcon,} from '@material-ui/icons';
import {getLogoutUrl} from "../../auth/hti-auth";
import Cookies from "js-cookie";

/**
 * A popover card that describes the signed-in user and provides some actions,
 * such as signing out.
 */
const UserCard = props => {

  const classes = useStyles();
  const {anchor, onClose} = props;


  const onSignOutClicked = () =>{
    Cookies.remove("hti-session", {path: "/", domain: ".hti.app"})
    Cookies.remove("hti-session", {path: "/", domain: "localhost"})
    window.location.href = getLogoutUrl();

  }

  return (<Popover
      open={!!anchor}
      anchorEl={anchor}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      onClose={onClose}
  >

    <MenuItem onClick={onSignOutClicked} className={classes.menuItem}>
      <SignOutIcon/>
      <Typography className={classes.menuItemText}>Sign Out</Typography>
    </MenuItem>

  </Popover>);

};

const useStyles = makeStyles(theme => ({
  customerName: {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  avatar: {
    backgroundColor: theme.palette.compliment["100%"],
    color: theme.palette.base["100%"],
  },
  menuItem: {padding: theme.spacing(2)},
  menuItemText: {marginLeft: theme.spacing()},
}))

UserCard.propTypes = {
  anchor: PropTypes.element,
  onClose: PropTypes.func.isRequired,
  fullName: PropTypes.string,
  initials: PropTypes.string,
};

export default UserCard;
