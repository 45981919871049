/* 
 * Created by Paul Engelke on 12 Feb 2019.
 */

/**
 * A complete set of the possible global message types.
 *
 * @type {{SUCCESS: string, ERROR: string, INFO: string, WARNING: string}}
 */
const GlobalMessageType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export default GlobalMessageType;
