/*
 * Created by Andre Richards on 17 Feb 2021.
 */

import React, { useEffect } from 'react';
import {getLoginUrl} from '../auth/hti-auth';

/**
 * This page simply redirects to HTI's login page
 *
 */
const LoginPage = () => {

  useEffect(() => {
    window.location.href = getLoginUrl();
  }, []);

  return (
      <div>Redirecting to login...</div>
  );
};

export default LoginPage;
