/*
 * Created by Paul Engelke on 23 February 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import SlideUpTransition from "./SlideUpTransition";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

/**
 * A common dialog component for asking the user to confirm an action.
 */
const ConfirmationDialog = (props) => {
  const {
    title, message, confirmButtonText, abortButtonText, open, onConfirm,
    onAbort, disabled,
  } = props;
  return (<Dialog open={open} TransitionComponent={SlideUpTransition}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button variant={'outlined'} onClick={onAbort} disabled={disabled}>
        {abortButtonText ?? 'Cancel'}
      </Button>
      <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={onConfirm}
          disabled={disabled}
      >{confirmButtonText ?? 'Ok'}</Button>
    </DialogActions>
  </Dialog>);
}

ConfirmationDialog.propTypes = {

  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string,
  abortButtonText: PropTypes.string,

  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
};

export default ConfirmationDialog;

