/* 
 * Created by Paul Engelke on 21 May 2019.
 */

import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import LightTheme from '../styles/lightTheme';
import DarkTheme from '../styles/darkTheme';
import store from "../store";
import App from "../components/App";
import RouteNames from "../constants/routeNames";
import {ComponentContextProvider} from '@hti-ui/react-web-material';
import {hot} from "react-hot-loader/root";
import PrivateRoute from "./PrivateRoute";
import {isDev} from "../util/config";
import LoginPage from "./LoginPage";
import {useMediaQuery} from "@material-ui/core";

const Root = () => {
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  // todo Default to the light theme until the ui library behaves correctly for
  //  dark mode.
  const prefersDarkMode = false;
  return (
      <ReduxProvider store={store}>
        <ComponentContextProvider
            theme={prefersDarkMode ? DarkTheme : LightTheme}
        >
          <Router>
            <Switch>
              <Route path={RouteNames.LoginPage}>
                <LoginPage/>
              </Route>
              <PrivateRoute path={RouteNames.Root} component={App}/>
            </Switch>
          </Router>
        </ComponentContextProvider>
      </ReduxProvider>)
};

export default isDev() ? hot(Root) : Root;
