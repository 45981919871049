/* 
 * This state part describes a global message queue that can be used to display
 * various messages to users, sequentially.
 *
 * Created by Paul Engelke on 12 Feb 2019.
 */

import {
  DEQUEUE_GLOBAL_MESSAGE,
  ENQUEUE_GLOBAL_MESSAGE
} from "../actions/ActionType";
import {tail} from 'lodash';

const DEFAULT_STATE = {
  queue: [],
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case ENQUEUE_GLOBAL_MESSAGE:
      return {...state, queue: [...state.queue, action.message]};

    case DEQUEUE_GLOBAL_MESSAGE:
      return {...state, queue: tail(state.queue)};

    default:
      return state;
  }
};

