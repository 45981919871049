/* 
 * Created by Paul Engelke on 21 May 2019.
 */

import {combineReducers} from 'redux';
import authenticationReducer from "./authenticationReducer";
import customerReducer from "./customerReducer";
import paymentGatewayReducer from "./paymentGatewayReducer";
import paymentGatewayConfigReducer from "./paymentGatewayConfigReducer";
import globalMessageReducer from "./globalMessageReducer";
import productNotifyErrorsReducer from "./productNotifyErrorsReducer";

export default combineReducers({
  authentication: authenticationReducer,
  customers: customerReducer,
  paymentGateways: paymentGatewayReducer,
  paymentGatewayConfigs: paymentGatewayConfigReducer,
  productNotifyErrors: productNotifyErrorsReducer,
  globalMessages: globalMessageReducer,
});
