/*
 * Created by Paul Engelke on 10 Jul 2019.
 */

import {useMemo} from 'react';
import {useDispatch} from "react-redux";
import {bindActionCreators} from "redux";

/**
 * A hook for binding action creators to the redux state. Commonly, action
 * creators are bound and injected into a component through a container via
 * 'mapDispatchToProps'.
 *
 * This hook allows us to access mapped action creators in other hooks and
 * functional components directly without needing a container.
 *
 * @param {array | object | function} actions The actions to be mapped to the
 * redux state dispatch.
 * @param {*[]} [deps] The action dependencies.
 * @return {array | object | function} A set of bound actions.
 */
const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      // noinspection JSCheckFunctionSignatures
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    // noinspection JSCheckFunctionSignatures
    return bindActionCreators(actions, dispatch);
  }, deps ? [dispatch, ...deps] : [dispatch]);
};

export default useActions;
