/* 
 * Created by Paul Engelke on 25 Jul 2018.
 */

import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const enhancers = [];
const middleware = [
  thunk
];

// noinspection JSUnresolvedVariable
// if (process.env.NODE_ENV === 'development') {

  // noinspection JSUnresolvedVariable
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof  devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
// }

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

// noinspection JSCheckFunctionSignatures
const store = createStore(
    rootReducer,
    {},
    composedEnhancers
);

export default store;