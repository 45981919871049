import React from 'react';
import PropTypes from 'prop-types';
import {
  TableColumnBuilder,
  TableSortDirection,
  VirtualTable
} from '@hti-ui/react-web-material';
import useActionConfirmation from "../../hooks/useActionConfirmation";

/**
 * A table for listing productNotifyErrors.
 */
const Table = props => {

  const {
    disabled,
    productNotifyError, productNotifyErrors, onProductNotifyErrorSelected,searchTerm
  } = props;

  const [confirmAction, confirmationDialog] = useActionConfirmation();

  const [columns, setColumns] = React.useState([]);
  React.useEffect(() => {
    setColumns([

      new TableColumnBuilder()
      .id('transactionReference').label('Transaction Reference')
      .sortable().sortDirection(TableSortDirection.ASC)
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('paymentReference').label('Payment Reference')
      .sortable()
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('transactionTimestamp').label('Transaction Timestamp')
      .sortable()
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('retryTimestamp').label('Retry Timestamp')
      .sortable()
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('retryCount').label('Retry Count')
      .sortable()
      .searchable()
      .build(),

      // TableColumnBuilder.ActionColumn().build(),

    ]);
  }, []);

  const isSelected = React.useCallback((other) => (
      productNotifyError?.id === other.id
  ), [productNotifyError]);

  return (<React.Fragment>

    <VirtualTable
        searchText={searchTerm}
        columns={columns}
        onColumnLabelClicked={setColumns}
        // actions={actions}
        data={productNotifyErrors}
        emptyListMessage={'No product notification errors found...'}
        onRowSelected={onProductNotifyErrorSelected}
        isSelectedRow={isSelected}
        disabled={disabled}
    />

    {confirmationDialog}

  </React.Fragment>);

};

Table.propTypes = {
  disabled: PropTypes.bool.isRequired,
  productNotifyError: PropTypes.object,
  productNotifyErrors: PropTypes.array,
  onProductNotifyErrorSelected: PropTypes.func.isRequired,
  searchTerm: PropTypes.string
};

export default Table;
