/*
 * Modified by Kevin Feb 2022.
 */

import {createTheme} from "@hti-ui/react-web-material";
import Palette from "./palette";

const Theme = createTheme({
  baseColor: Palette.charcoal,
  complimentColor: Palette.white,
  secondaryColor: Palette.secondary,
  customColors: Palette,
});

export default Theme;
