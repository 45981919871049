/*
 * Created by Andre Richards on 3 March 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  TableColumnBuilder,
  TableSortDirection,
  VirtualTable
} from '@hti-ui/react-web-material';
import {IconButton} from "@material-ui/core";
import {DeleteOutlineRounded as DeleteIcon} from '@material-ui/icons';
import {deletePaymentGateway} from "../../actions/paymentGatewayActions";
import useActions from "../../hooks/useActions";
import useActionConfirmation from "../../hooks/useActionConfirmation";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A table for listing PaymentGateways.
 */
const Table = props => {

  const {
    disabled, searchTerm,
    paymentGateway, paymentGateways, onPaymentGatewaySelected,
  } = props;

  const [_deletePaymentGateway] = useActions([deletePaymentGateway]);
  const [confirmAction, confirmationDialog] = useActionConfirmation();
  const {dispatchSuccessMessage, handleError} = useGlobalMessenger();

  const [columns, setColumns] = React.useState([]);
  React.useEffect(() => {
    setColumns([

      new TableColumnBuilder()
      .id('name').label('Name')
      .sortable().sortDirection(TableSortDirection.ASC)
      .searchable()
      .build(),

      // TableColumnBuilder.ActionColumn().build(),

    ]);
  }, []);

  /**
   * Checks if a paymentGateway in the table is the selected paymentGateway.
   */
  const isSelected = React.useCallback((other) => (
      paymentGateway?.id === other.id
  ), [paymentGateway]);

  /**
   * Deletes a PaymentGateway with user confirmation.
   */
  const onDeleteClicked = React.useCallback((paymentGateway) => {
    confirmAction(() => {

      _deletePaymentGateway({id: paymentGateway?.id})
      .then(() => {
        dispatchSuccessMessage('The Payment Gateway was deleted.');
      })
      .catch(e => {
        handleError(e, 'The Payment Gateway could not be deleted.');
      });

    }, {
      title: 'Delete Payment Gateway?',
      message: `Are you sure you want to delete '${paymentGateway.name}'?`,
      confirmLabel: 'Delete',
    });
  }, [_deletePaymentGateway,
    confirmAction, dispatchSuccessMessage, handleError,
  ]);

  /**
   * Action definitions for each row in the table.
   */
  const actions = React.useMemo(() => ([
    {
      Component: IconButton,
      componentProps: {children: (<DeleteIcon/>)},
      onClick: onDeleteClicked,
      tooltip: 'Delete Payment Gateway'
    }
  ]), [onDeleteClicked]);

  return (<React.Fragment>

    <VirtualTable
        searchText={searchTerm}
        columns={columns}
        onColumnLabelClicked={setColumns}
        actions={actions}
        data={paymentGateways}
        emptyListMessage={'No Payment Gateways found...'}
        onRowSelected={onPaymentGatewaySelected}
        isSelectedRow={isSelected}
        disabled={disabled}
    />

    {confirmationDialog}

  </React.Fragment>);

};

Table.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  paymentGateway: PropTypes.object,
  paymentGateways: PropTypes.array.isRequired,
  onPaymentGatewaySelected: PropTypes.func.isRequired,
};

export default Table;
