import {isDev} from "../util/config";
import Cookies from "js-cookie";

const LOGIN_URL_BASE = "https://login.hti.app?app=hps";

const PROD_DOMAINS = [
  'pay.hti.app',
];

const TEST_DOMAINS = [
  'test-pay.hti.app',
];

export const getLoginUrl = () => {
  const host = window.location.host;
  let loginUrl;
  if (!isDev()) {
    if (PROD_DOMAINS.includes(host)) {
        loginUrl = LOGIN_URL_BASE + "&authSessionUrl=https://apps.hti.app/payment-switch/authSession&signInSuccessUrl=https://pay.hti.app";
    } else if (TEST_DOMAINS.includes(host)) {
      loginUrl = LOGIN_URL_BASE + "&authSessionUrl=https://test.hti.app/payment-switch/authSession&signInSuccessUrl=https://test-pay.hti.app";
    } else {
      console.error('Host: ' + host + ' not one of ' + PROD_DOMAINS + ' or '
          + TEST_DOMAINS)
    }
  } else {
    loginUrl = LOGIN_URL_BASE + "&authSessionUrl=http://localhost:8080/authSession&signInSuccessUrl=http://localhost:3000";
  }
  return loginUrl;
};


export const getLogoutUrl = () => {

  let loginUrl =  getLoginUrl()
    loginUrl = loginUrl + '&logout=true'
  return loginUrl;
};

export const isLoggedIn = () => {
  return Cookies.get('hti-session') !== null && Cookies.get('hti-session') !== undefined;
};

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}