/* 
 * A set of codes used to provide additional information in conjunction with
 * the standard HTTP codes.
 *
 *
 * Created by Paul Engelke on 22 Aug 2018.
 */

const AppStatusCode = {

  // Axios specific errors that might occur
  CONNECTION_TIMEOUT: 'ECONNABORTED',
  NETWORK_ERROR: 'NETWORK_ERROR',
  AUTHENTICATION: 'AUTHENTICATION',

  UNKNOWN: -666, // these are the devil
};

export default AppStatusCode;
