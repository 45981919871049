import React from 'react';
import PropTypes from 'prop-types';
import {
  SideDrawer,
  SideDrawerContent,
  SideDrawerToolbar,
  TextField,
} from "@hti-ui/react-web-material";
import {Button, Grid} from "@material-ui/core";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import useActions from "../../hooks/useActions";
import {resend} from "../../actions/productNotifyErrorActions";

const Drawer = props => {

  const {visible, disabled, dismiss, productNotifyError} = props;

  const {dispatchSuccessMessage, handleError} = useGlobalMessenger();
  const [_resend] = useActions([resend]);

  const [transactionMap, setTransactionMap] = React.useState(
      productNotifyError?.transactionMap);
  const [transactionReference, setTransactionReference] = React.useState(
      productNotifyError?.transactionReference);
  const [transactionTimestamp, setTransactionTimestamp] = React.useState(
      productNotifyError?.transactionTimestamp);
  const [paymentReference, setPaymentReference] = React.useState(
      productNotifyError?.paymentReference);
  const [paymentGatewayTransactionId, setPaymentGatewayTransactionId] = React.useState(
      productNotifyError?.paymentGatewayTransactionId);
  const [gatewayResult, setGatewayResult] = React.useState(
      productNotifyError?.gatewayResult);
  const [postStatus, setPostStatus] = React.useState(
      productNotifyError?.postStatus);
  const [postResult, setPostResult] = React.useState(
      productNotifyError?.postResult);
  const [retryTimestamp, setRetryTimestamp] = React.useState(
      productNotifyError?.retryTimestamp);
  const [retryCount, setRetryCount] = React.useState(
      productNotifyError?.retryCount);
  const [canResend, setCanResend] = React.useState(false);
  React.useEffect(() => {
    setTransactionMap(productNotifyError?.transactionMap);
    setTransactionReference(productNotifyError?.transactionReference);
    setTransactionTimestamp(productNotifyError?.transactionTimestamp);
    setPaymentReference(productNotifyError?.paymentReference);
    setPaymentGatewayTransactionId(
        productNotifyError?.paymentGatewayTransactionId);
    setGatewayResult(productNotifyError?.gatewayResult);
    setPostStatus(productNotifyError?.postStatus);
    setPostResult(productNotifyError?.postResult);
    setRetryTimestamp(productNotifyError?.retryTimestamp);
    setRetryCount(productNotifyError?.retryCount);
    setCanResend(!productNotifyError?.retryTimestamp)
  }, [productNotifyError]);

  const doResend = React.useCallback(() => {
    _resend({productNotifyError})
    .then(() => {
      dispatchSuccessMessage('The transaction was resent');
      dismiss(true);
    })
    .catch(e => {
      handleError(e,'The transaction could not be resent.');
      dismiss(true);
    })
  }, [productNotifyError])

  return (<SideDrawer visible={visible} md={8} xs={12}>

    <SideDrawerToolbar
        title={'Product Notify Error'}
    >

      <Button
          variant={'outlined'}
          onClick={()=>dismiss(false)}
          disabled={disabled}
      >Close</Button>

      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={doResend}
          disabled={disabled || !canResend}
      >{"Resend"}</Button>

    </SideDrawerToolbar>

    <SideDrawerContent>

      <Grid container spacing={1}>

        <Grid item xs={12}>
          <TextField
              label={'Transaction Map'}
              value={transactionMap}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Transaction Reference'}
              value={transactionReference}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Transaction Timestamp'}
              value={transactionTimestamp}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Payment Reference'}
              value={paymentReference}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Payment Gateway Transaction Id'}
              value={paymentGatewayTransactionId}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Gateway Result'}
              value={gatewayResult}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Post Status'}
              value={postStatus}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Post Result'}
              value={postResult}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Retry Timestamp'}
              value={retryTimestamp}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={'Retry Count'}
              value={retryCount}
              onChange={() => {
              }}
              fullWidth
              disabled
          />
        </Grid>


      </Grid>

    </SideDrawerContent>

  </SideDrawer>);

};

Drawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  productNotifyError: PropTypes.object,
};

export default Drawer;
