/* 
 * Created by Paul Engelke on 21 May 2019.
 */

import {BasePalette} from "@hti-ui/react-web-material";

const Palette = {
  ...BasePalette,
  secondary: BasePalette.blue,
};

export default Palette;