/*
 * Created by Andre Richards on 3 March 2021.
 */

import React from 'react';
import {useSelector} from "react-redux";
import {StateUtility} from "@hti-ui/redux-core";
import {
  LoadingIndicator,
  Page,
  PageContent,
  PageToolbar,
  SearchField,
} from "@hti-ui/react-web-material";
import Drawer from "./Drawer";
import Table from "./Table";
import {Button, IconButton} from "@material-ui/core";
import {RefreshRounded as RefreshIcon} from '@material-ui/icons';
import useActions from "../../hooks/useActions";
import {fetchPaymentGatewayConfigs} from "../../actions/paymentGatewayConfigActions";
import {fetchCustomers} from "../../actions/customerActions";
import {fetchPaymentGateways} from "../../actions/paymentGatewayActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A page for managing PaymentGatewayConfigs.
 */
const PaymentGatewayConfigPage = () => {

  const {loading, paymentGatewayConfigs, customers, paymentGateways} = useSelector(state => ({
    loading: StateUtility.isBusy(state.paymentGatewayConfigs),
    paymentGatewayConfigs: state.paymentGatewayConfigs.data,
    customers: state.customers.data,
    paymentGateways: state.paymentGateways.data,
  }));

  const [_fetchPaymentGatewayConfigs, _fetchCustomers, _fetchPaymentGateways] = useActions(
      [fetchPaymentGatewayConfigs, fetchCustomers, fetchPaymentGateways]);
  const {handleError} = useGlobalMessenger();

  const [showDrawer, toggleDrawer] = React.useState(false);
  const [paymentGatewayConfig, setPaymentGatewayConfig] = React.useState(null);

  const [searchTerm, setSearchTerm] = React.useState('');
  const trimmedSearchTerm = searchTerm.trim();

  /**
   * Fetches all the data required by this page and its subcomponents.
   */
  const fetchData = React.useCallback((force = false) => {

    Promise.all([
      _fetchCustomers({force}),
      _fetchPaymentGateways({force}),
      _fetchPaymentGatewayConfigs({force}),
    ])
    .catch(e => {
      handleError(e, 'An error occurred while fetching data.');
    });

  }, [_fetchCustomers, _fetchPaymentGateways, _fetchPaymentGatewayConfigs, handleError]);

  React.useEffect(() => fetchData(false), [fetchData]);

  /**
   * Ensures that the selected paymentGatewayConfig is null and shows the drawer so that a
   * new customer can be created.
   */
  const onCreateClicked = React.useCallback(() => {
    setPaymentGatewayConfig(null);
    toggleDrawer(true);
  }, []);

  /**
   * Sets the selected paymentGatewayConfig and shows the drawer to update the paymentGatewayConfig.
   */
  const onPaymentGatewayConfigSelected = React.useCallback((paymentGatewayConfig) => {
    setPaymentGatewayConfig(paymentGatewayConfig);
    toggleDrawer(true);
  }, []);

  /**
   * Hides the drawer and clears the selected PaymentGatewayConfig.
   */
  const onDrawerDismissed = React.useCallback(() => {
    setPaymentGatewayConfig(null);
    toggleDrawer(false);
  }, []);

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <PageToolbar
        title={'Payment Gateway Configs'}
        subtitle={'View and manage Payment Gateway Configs.'}
    >

      <SearchField
          placeholder={'Find...'}
          value={searchTerm}
          onChange={setSearchTerm}
          disabled={loading}
      />

      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={onCreateClicked}
          disabled={loading}
      >Create</Button>

      <IconButton onClick={fetchData} disabled={loading}>
        <RefreshIcon/>
      </IconButton>

    </PageToolbar>

    <PageContent>

      <Table
          paymentGatewayConfig={paymentGatewayConfig}
          paymentGatewayConfigs={paymentGatewayConfigs}
          onPaymentGatewayConfigSelected={onPaymentGatewayConfigSelected}
          searchTerm={trimmedSearchTerm}
          disabled={loading}
      />

      <Drawer
          visible={showDrawer}
          disabled={loading}
          dismiss={onDrawerDismissed}
          paymentGatewayConfig={paymentGatewayConfig}
          customers={customers}
          paymentGateways={paymentGateways}
      />

    </PageContent>

  </Page>);

};

export default PaymentGatewayConfigPage;
