/* 
 * Created by Andre Richards on 20 Feb 2020.
 */

import React, {useCallback, useMemo, useState} from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {
  NavigationBar as HtiNavBar,
  NavigationBarButtonGroup,
  NavigationBarCrest,
  NavigationDrawer
} from '@hti-ui/react-web-material';
import {withRouter} from "react-router-dom";
import RouteNames from "../constants/routeNames";
import {makeStyles} from "@material-ui/core/styles";
import CircleLogo from '../assets/images/nebula-PAY-icon.svg';
import UserWidget from "./UserWidget";
import NavigationQuickLinks from "./Navigation/NavigationQuickLinks";
import {
  DashboardOutlined as DashboardIcon,
  SupervisedUserCircleOutlined as CustomersIcon,
  AccountBalanceRounded as PaymentGatewayIcon,
  SettingsOutlined as PaymentGatewayConfigIcon,
  ErrorOutlined as ProductNotifyErrorIcon
} from '@material-ui/icons';
import DrawerHeader from "./Navigation/DrawerHeader";

/**
 * A navigation bar for the example app. This demonstrates how a navigation bar
 * can be constructed using common navigation components provided by
 * @hti-ui/react-web-material.
 *
 * @return {*}
 * @constructor
 */
const NavigationBar = (props) => {

  const {history} = props;
  const classes = useStyles(props);

  const navigationTree = useMemo(() => ({
    children: [
      {
        id: RouteNames.DashboardPage,
        label: 'Dashboard',
        onClick: () => history.push(RouteNames.DashboardPage),
        quickLink: {IconComponent: DashboardIcon},
        children: [],
      },
      {
        id: RouteNames.CustomerPage,
        label: 'Customers',
        quickLink: {IconComponent: CustomersIcon},
        onClick: () => history.push(RouteNames.CustomerPage),
      },
      {
        id: RouteNames.PaymentGatewayPage,
        label: 'Payment Gateways',
        quickLink: {IconComponent: PaymentGatewayIcon},
        onClick: () => history.push(RouteNames.PaymentGatewayPage),
        children: [],
      },
      {
        id: RouteNames.PaymentGatewayConfigPage,
        label: 'Payment Gateway Configs',
        quickLink: {IconComponent: PaymentGatewayConfigIcon},
        onClick: () => history.push(RouteNames.PaymentGatewayConfigPage),
        children: [],
      },
      {
        id: RouteNames.ProductNotificationErrorPage,
        label: 'Product Notify Errors',
        quickLink: {IconComponent: ProductNotifyErrorIcon},
        onClick: () => history.push(RouteNames.ProductNotificationErrorPage),
        children: [],
      }
    ],
  }), [history]);

  const [navigationState, setNavigationState] = useState({term: '', stack: []});
  const onNavigationStateChanged = useCallback((state) => {
    setNavigationState(state);
  }, []);

  const [drawerVisible, setDrawerVisibility] = useState(false);
  const openDrawer = useCallback(() => setDrawerVisibility(true), []);
  const closeDrawer = useCallback(() => {
    setDrawerVisibility(false);
    setNavigationState({term: '', stack: []});
  }, []);

  const onQuickLinkNavigation = useCallback((node) => {

    if (node.onClick) {
      // The node refers to a screen in the app.
      // All we need to do is navigate to the screen.
      node.onClick();
      return;
    }

    // Otherwise, we need to set the navigation state to point to the node and
    // open the drawer.

    const hasChildren = node.children?.length > 0;
    setNavigationState(prev => ({
      ...prev,
      stack: hasChildren ? [node.id] : [],
    }));

    setDrawerVisibility(hasChildren);

  }, [])

  return (<HtiNavBar>
    <NavigationBarCrest onClick={openDrawer}>
      <img src={CircleLogo} className={classes.logo} alt={'NebulaCRS Logo'}/>
    </NavigationBarCrest>
    <NavigationQuickLinks
        tree={navigationTree}
        onQuickLinkClicked={onQuickLinkNavigation}
    />
    <NavigationBarButtonGroup align={"bottom"}>
      <UserWidget/>
    </NavigationBarButtonGroup>
    <NavigationDrawer
        open={drawerVisible}
        onClose={closeDrawer}
        navigationState={navigationState}
        searchPlaceholder={'Search'}
        onNavigationStateChanged={onNavigationStateChanged}
        navigationTree={navigationTree}
        header={<DrawerHeader/>}
    />
  </HtiNavBar>);
};

NavigationBar.propTypes = {
  history: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 45,
    marginBottom: theme.unit,
  },
}));

export default withRouter(NavigationBar);
