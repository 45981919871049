/* 
 * Created by Andre Richards on 20 Feb 2020.
 */

import React from 'react';
import {Page, PageToolbar} from "@hti-ui/react-web-material";
// import {makeStyles} from "@material-ui/core/styles";

/**
 * DashboardPage shows overview of important metrics
 *
 * @return {*}
 * @constructor
 */
const DashboardPage = () => {
  // const classes = useStyles();
  return (<Page>
    <PageToolbar title={'Dashboard'}/>
{/*    <PageContent>

    </PageContent>*/}
  </Page>);
};

/**
 * A stylesheet hook for the component.
 *
 * @type {function | *}
 */
// const useStyles = makeStyles(({spacing}) => {
//   return ({
//     p: {
//       marginBottom: spacing(1),
//     },
//     list: {
//       marginLeft: spacing(3),
//       marginBottom: spacing(1),
//     }
//   });
// });

export default DashboardPage;
