/* 
 * Created by Paul Engelke on 21 May 2019.
 */

/**
 * A set of defined routes for the app.
 */
const RouteNames = {
  LoginPage: '/login',
  Root: '/',
  DashboardPage: '/dashboard',
  CustomerPage: '/customers',
  PaymentGatewayPage: '/paymentgateways',
  PaymentGatewayConfigPage: '/paymentgatewayconfigs',
  ProductNotificationErrorPage: '/productnotificationerrors'
};

export default RouteNames;
