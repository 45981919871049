import GlobalActionTypes from "../constants/action-types/globalActionTypes";
import {LocalCacheRegistry} from "@hti-ui/local-cache";

export const verifyIdToken = (idTokenResult) => {
  const isValid = true; // TODO check if not expired
  if (isValid) {
    const verifiedLogin = {
      id: idTokenResult.claims.userId,
      uid: idTokenResult.claims.uid,
      name: idTokenResult.claims.loginName,
    };
    return verifiedLogin;
  }
  else {
    return undefined;
  }
};

/**
 * Performs global state clean up, to be called when the user explicitly signs
 * out or when their session expires.
 *
 * @return {function(*,*): void}
 */
export const signOut = () => (dispatch) => {
  // todo any other clean up, token invalidation, etc.
  dispatch({type: GlobalActionTypes.RESET});
  LocalCacheRegistry.clear();
}
