/*
 * Created by Kevin, Feb 2022
 */

import {NavigationBarButtonGroup, Tooltip} from "@hti-ui/react-web-material";
import {IconButton, makeStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import {useMemo} from "react";
import {HelpOutline as UnknownIcon} from '@material-ui/icons';

/**
 * A component for generating and displaying a set of quick links on the app
 * navigation bar.
 */
const NavigationQuickLinks = props => {

  const {tree, onQuickLinkClicked} = props;
  const classes = useStyles();

  const links = useMemo(() => {

    const links = [];
    const stack = [tree];

    while (stack.length > 0) {

      const node = stack.shift();
      if (node.quickLink) {
        links.push({
          key: node.id,
          tooltip: node.label,
          IconComponent: node.quickLink.IconComponent ?? UnknownIcon,
          onClick: () => onQuickLinkClicked(node),
        });
      }

      if (node.children?.length > 0) {
        // Add the node's children to the stack for evaluation.
        stack.push(...node.children);
      }

    }

    return links;

  }, [tree, onQuickLinkClicked]);

  return (<NavigationBarButtonGroup>
    {links.map(link => (
        <Tooltip key={link.key} placement={'right'} content={link.tooltip}>
          <IconButton className={classes.link} onClick={link.onClick}>
            <link.IconComponent/>
          </IconButton>
        </Tooltip>
    ))}
  </NavigationBarButtonGroup>);

};

NavigationQuickLinks.propTypes = {
  tree: PropTypes.object.isRequired,
  onQuickLinkClicked: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
  link: {color: theme.palette.common.white},
}));

export default NavigationQuickLinks;
