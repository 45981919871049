/* 
 * Created by Paul Engelke on 09 May 2019.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import RouteNames from "../constants/routeNames";
import {isLoggedIn} from "../auth/hti-auth";

/**
 * A route that is protected by user authentication. If the route is accessed
 * and no valid authentication is provided, the user will be redirected to the
 * sign-in page.
 *
 * @see https://reacttraining.com/react-router/web/api/Route
 */
const PrivateRoute = ({component: Component, ...rest}) => {

  return (<Route
      {...rest}
      render={(props) => {
        return isLoggedIn()
            ? (<Component {...props}/>)
            : (<Redirect to={{
              pathname: RouteNames.LoginPage,
              state: {from: props.location}}}/>);
        }}
/>)};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
  ]).isRequired,
};

export default PrivateRoute;
