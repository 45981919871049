/*
 * Created by Andre Richards on 3 March 2021.
 */

import React from 'react';
import {useSelector} from "react-redux";
import {StateUtility} from "@hti-ui/redux-core";
import {
  LoadingIndicator,
  Page,
  PageContent,
  PageToolbar,
  SearchField,
} from "@hti-ui/react-web-material";
import Drawer from "./Drawer";
import Table from "./Table";
import {IconButton} from "@material-ui/core";
import {RefreshRounded as RefreshIcon} from '@material-ui/icons';
import useActions from "../../hooks/useActions";
import {fetchPaymentGateways} from "../../actions/paymentGatewayActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A page for managing PaymentGateways.
 */
const PaymentGatewayPage = () => {

  const {loading, paymentGateways} = useSelector(state => ({
    loading: StateUtility.isBusy(state.paymentGateways),
    paymentGateways: state.paymentGateways.data,
  }));

  const [_fetchPaymentGateways] = useActions([fetchPaymentGateways]);
  const {handleError} = useGlobalMessenger();

  const [showDrawer, toggleDrawer] = React.useState(false);
  const [paymentGateway, setPaymentGateway] = React.useState(null);

  const [searchTerm, setSearchTerm] = React.useState('');
  const trimmedSearchTerm = searchTerm.trim();

  /**
   * Fetches all the data required by this page and its subcomponents.
   */
  const fetchData = React.useCallback((force = false) => {

    _fetchPaymentGateways({force})
    .catch(e => {
      handleError(e, 'An error occurred while fetching data.');
    });

  }, [_fetchPaymentGateways, handleError]);

  React.useEffect(() => fetchData(false), [fetchData]);

/*
  /!**
   * Ensures that the selected PaymentGateway is null and shows the drawer so that a
   * new PaymentGateway can be created.
   *!/
  const onCreateClicked = React.useCallback(() => {
    setPaymentGateway(null);
    toggleDrawer(true);
  }, []);
*/

  /**
   * Sets the selected paymentGateway and shows the drawer to update the PaymentGateway.
   */
  const onPaymentGatewaySelected = React.useCallback((paymentGateway) => {
    setPaymentGateway(paymentGateway);
    toggleDrawer(true);
  }, []);

  /**
   * Hides the drawer and clears the selected PaymentGateway.
   */
  const onDrawerDismissed = React.useCallback(() => {
    setPaymentGateway(null);
    toggleDrawer(false);
  }, []);

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <PageToolbar
        title={'Payment Gateways'}
        subtitle={'View and manage Payment Gateways.'}
    >

      <SearchField
          placeholder={'Find...'}
          value={searchTerm}
          onChange={setSearchTerm}
          disabled={loading}
      />

      {/*/!*<Button*/}
      {/*    variant={'contained'}*/}
      {/*    color={'secondary'}*/}
      {/*    onClick={onCreateClicked}*/}
      {/*    disabled={loading}*/}
      {/*>Create</Button>*!/*/}

      <IconButton onClick={fetchData} disabled={loading}>
        <RefreshIcon/>
      </IconButton>

    </PageToolbar>

    <PageContent>

      <Table
          paymentGateway={paymentGateway}
          paymentGateways={paymentGateways}
          onPaymentGatewaySelected={onPaymentGatewaySelected}
          searchTerm={trimmedSearchTerm}
          disabled={loading}
      />

      <Drawer
          visible={showDrawer}
          disabled={loading}
          dismiss={onDrawerDismissed}
          paymentGateway={paymentGateway}
      />

    </PageContent>

  </Page>);

};

export default PaymentGatewayPage;
