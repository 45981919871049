/* 
 * Created by Paul Engelke on 22 Aug 2018.
 */

const HttpStatusCode = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export default HttpStatusCode;
