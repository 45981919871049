/*
 * Created by Paul Engelke on 22 February 2021.
 */

import {Operation} from '../net/api';
import axios from 'axios';
import HttpError from "./error/httpError";
import Cookies from "js-cookie";
import {isDev} from "./config";

const PROD_DOMAINS = [
  'pay.hti.app',
];

const TEST_DOMAINS = [
  'test-pay.hti.app',
];

/**
 * A utility for sending requests to the server.
 */
export default class HttpManager {

  /**
   * Gets the base URL for all requests to the server.
   * @return {string}
   * @private
   */
  static get BaseUrl() {
    const host = window.location.host;
    let baseUrl;
    if (!isDev()) {
      if (PROD_DOMAINS.includes(host)) {
        baseUrl = 'https://apps.hti.app/payment-switch';
      } else if (TEST_DOMAINS.includes(host)) {
        baseUrl = 'https://test.hti.app/payment-switch';
      } else {
        console.error('Host: ' + host + ' not one of ' + PROD_DOMAINS + ' or '
            + TEST_DOMAINS)
      }
    } else {
      baseUrl = 'http://localhost:8080';
    }

    return baseUrl + '/rest';
  }

  /**
   * Sends a 'GET' request to the server.
   *
   * @param {Object} args The function arguments.
   * @param {string} args.entity The modelled entity.
   * @param {Object} [args.payload] The request payload.
   * @return {Promise}
   */
  static get(args) {

    const {entity, payload} = args ?? {};
    if (!entity) {
      return Promise.reject(new Error('No entity type provided.'));
    }

    return axios.post(
        this.BaseUrl,
        {
          entity,
          operation: Operation.FETCH,
          ...(payload ?? {}),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('hti-session')}`}
        })
    .catch(e => {
      throw new HttpError(e);
    });

  }

  static send(data) {

    return axios.post(this.BaseUrl, data)
    .catch(e => {
      throw new HttpError(e);
    });

  }

  /**
   * Sends a 'POST' request to the server.
   *
   * @param {Object} args The function arguments.
   * @param {string} args.entity The modelled entity.
   * @param {Object} args.payload The request payload.
   * @param {string} args.url This will be appended to the base url
   * @return {Promise}
   */
  static post(args) {

    const {entity, payload, url} = args;
    if (!entity) {
      return Promise.reject(new Error('No entity type provided.'));
    }

    return axios.post(
        this.BaseUrl+(!!url ? "/"+url : ""),
        {
          entity,
          operation: Operation.CREATE,
          data: payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('hti-session')}`}
        })
    .catch(e => {
      throw new HttpError(e);
    });

  }

  /**
   * Sends a 'PUT' request to the server.
   *
   * @param {Object} args The function arguments.
   * @param {string} args.entity The modelled entity.
   * @param {Object} args.payload The request payload.
   * @return {Promise}
   */
  static put(args) {

    const {entity, payload} = args;
    if (!entity) {
      return Promise.reject(new Error('No entity type provided.'));
    }

    return axios.post(
        this.BaseUrl,
        {
          entity,
          operation: Operation.UPDATE,
          data: payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('hti-session')}`}
        })
    .catch(e => {
      throw new HttpError(e);
    });

  }

  /**
   * Sends a 'DELETE' request to the server.
   *
   * @param {Object} args The function arguments.
   * @param {string} args.entity The modelled entity.
   * @param {Object} args.payload The request payload.
   * @return {Promise}
   */
  static delete(args) {

    const {entity, payload} = args;
    if (!entity) {
      return Promise.reject(new Error('No entity type provided.'));
    }

    return axios.post(
        this.BaseUrl,
        {
          entity,
          operation: Operation.DELETE,
          data: payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('hti-session')}`}
        })
    .catch(e => {
      throw new HttpError(e);
    });

  }

}
