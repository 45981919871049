/*
 * Created by Kevin on 14 August 2023.
 */

import {LocalCache, LocalCacheRegistry} from '@hti-ui/local-cache';
import HttpManager from "../util/httpManager";
import actionTypes from "../constants/action-types/productNotifyErrorTypes";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * The modelled entity on the server, for which all operations below are
 * performed.
 * @type {string}
 */
const entity = 'productNotifyError';

/**
 * Fetches a list of Product Notif Errors.
 *
 * @param {Object} args The function arguments, including request parameters.
 * @param {boolean} [args.force=false] Should local cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchProductNotificationErrors = (args) => (dispatch) => {

  const key = 'productNotifyErrors';
  const {paymentGatewayConfigId} = args;
  dispatch({type: actionTypes.REQUEST});
  const request = HttpManager.get({
    entity,
    payload : {data : {paymentGatewayConfigId}}
  });

  return request
  .then(r => {
    dispatch({type: actionTypes.SET, data: r?.data?.data ?? []});
    return r;
  })
  .catch(e => {
    cache.delete(key);
    dispatch({type: actionTypes.FAIL_REQUEST});
    throw e;
  })

};

/**
 * Resends a product notif that is failed
 *
 * @param {Object} args The request parameters.
 * @param {string} args.name The new customer's name.
 * @return {function(*,*): Promise}
 */
export const resend = (args) => (dispatch) => {
  console.log(args)
  const {productNotifyError} = args;
  dispatch({type: actionTypes.REQUEST});
  return HttpManager.post(
      {entity, payload: {productNotifyError}, url: "productNotifyError"})
  .then(r => {
    dispatch({type: actionTypes.REQUEST, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: actionTypes.FAIL_REQUEST});
    throw e;
  });
};
