/*
 * Created by Paul Engelke on 22 February 2021.
 */

import {AxiosError} from 'axios';
import AppStatusCode from "../../constants/codes/appStatusCodes";
import StdError from "./stdError";

/**
 * A class defining HTTP errors for the app.
 */
export default class HttpError extends StdError {

  /**
   * Creates a new HttpError
   *
   * @param {AxiosError | HttpError} [error] An error that occurred as a result
   * of a failed HTTP request.
   * @param {string} [message] A descriptive message for the error.
   */
  constructor(error, message) {

    super(message, error);

    if (!(error instanceof HttpError)) {

      this.setError(error);
      this.setMessage(message);

      if (!error?.response && !error?.code) {
        this.setCode(AppStatusCode.NETWORK_ERROR);
      } else if (error?.response) {
        const {data, status} = error.response;
        if (data?.code) {
          this.setCode(data.code);
        } else if (status) {
          this.setCode(status);
        }
      }

      if (!this.getCode()) {
        this.setCode(error?.code ?? AppStatusCode.UNKNOWN)
      }

    }
  }
}

