/* 
 * Created by Paul Engelke on 12 Feb 2019.
 */

import {
  DEQUEUE_GLOBAL_MESSAGE,
  ENQUEUE_GLOBAL_MESSAGE
} from "./ActionType";
import GlobalMessageType from "../constants/globalMessageTypes";
import {CoreDate} from "@hti-ui/js-core";

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @return {function(*,*): void}
 */
export const dispatchSuccessMessage = (message) => dispatchMessage({
  description: message,
  type: GlobalMessageType.SUCCESS,
});

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @return {function(*,*): void}
 */
export const dispatchWarningMessage = (message) => dispatchMessage({
  description: message,
  type: GlobalMessageType.WARNING,
});

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @return {function(*,*): void}
 */
export const dispatchErrorMessage = (message) => dispatchMessage({
  description: message,
  type: GlobalMessageType.ERROR,
});

/**
 * Displays the a success message to the user.
 *
 * @param {string} message The message to display.
 * @return {function(*,*): void}
 */
export const dispatchInfoMessage = (message) => dispatchMessage({
  description: message,
  type: GlobalMessageType.INFO,
});

/**
 * Adds the given message object to the global message queue.
 *
 * @param {object} message The message to queue.
 * @return {function(*,*): void}
 */
const dispatchMessage = (message) => (dispatch) => dispatch({
  type: ENQUEUE_GLOBAL_MESSAGE,
  message: {
    ...message,
    timestamp: CoreDate.now(),
  }
});

/**
 * Removes the oldest message from the queue. This should only be done once the
 * user has seen the message.
 *
 * @return {function(*,*): void}
 */
export const dequeueGlobalMessage = () => (dispatch) => dispatch({
  type: DEQUEUE_GLOBAL_MESSAGE,
});
