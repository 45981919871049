/*
 * Created by Paul Engelke on 23 February 2021.
 */

import React from 'react';
import useActions from "../hooks/useActions";
import {dequeueGlobalMessage} from "../actions/globalMessageActions";
import {Snackbar, SnackbarContent} from "@hti-ui/react-web-material";
import {useSelector} from "react-redux";

/**
 * A snackbar to be appended to the 'root' app component.
 *
 * This component is responsible for displaying global messages and dequeuing
 * them from the global message redux state.
 */
const GlobalSnackbar = () => {

  const messages = useSelector(state => state.globalMessages.queue);
  const current = messages?.[0];

  const [dequeMessage] = useActions([dequeueGlobalMessage]);
  const onShowComplete = React.useCallback(() => dequeMessage(), [ dequeMessage]);

  return (<Snackbar
      visible={!!current}
      duration={3000}
      type={current?.type}
      onShowComplete={onShowComplete}
  >
    <SnackbarContent>{current?.description ?? ''}</SnackbarContent>
  </Snackbar>);

};

export default GlobalSnackbar;
