

/**
 * A set of API operation types.
 */
export const Operation = Object.freeze({
  FETCH: 'fetch',
  CREATE: 'add',
  UPDATE: 'update',
  DELETE: 'remove',
});

export const DEFAULT_FETCH_RQ = Object.freeze({
      dataSource: undefined,
      operationType: Operation.FETCH,
    }
);
