import configFields from "../PaymentGatewayConfigPage/Fields";
import paymentGatewayFields from "../PaymentGatewayPage/Fields";

const gatewayFields = Object.freeze({
  'Halo': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {
      required:true,
      gatewayName : "Intent Url"}],
      [paymentGatewayFields.QUERY_URL, {
      required: true,
        gatewayName: "Transactions Details URL"
      }],
      [paymentGatewayFields.REFUND_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {
        required: true,
      }],
      [configFields.CUSTOMER, {
        required: true,
      }],
      [configFields.CUSTOMER_ID, {
        required: true,
      }],
      [configFields.PAYMENT_GATEWAY, {
        required: true,
      }],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SECRET_KEY, {
        required: true,
        gatewayName: 'API Key'
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]]
    ),
  },
  'iVeri': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.REDIRECT_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  'iVeri-IM': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.REDIRECT_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  'mPesa': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {
        required: true,
        gatewayName: "M-PESA express url"
      }],
      [paymentGatewayFields.QUERY_URL, {
        required: true
      }],
      [paymentGatewayFields.REFUND_URL, {}],
      [paymentGatewayFields.INITIATE_URL, {required: true,
        gatewayName: "Auth Url"}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
        gatewayName: "Short Code"
      }],
      [configFields.SECRET_KEY, {
        required: true,
        gatewayName: "Passkey"
      }],
      [configFields.USERNAME, {
        required: true,
        gatewayName: "Consumer Key"
      }],
      [configFields.PASSWORD, {
        required: true,
        gatewayName: "Consumer Secret"
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}],
          [configFields.TEST_MODE, {}]
    ]),
  },
  'PayWeb': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {}],
      [paymentGatewayFields.REDIRECT_URL, {}],
      [paymentGatewayFields.QUERY_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SECRET_KEY, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  'Peach': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {}],
      [paymentGatewayFields.QUERY_URL, {}],
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SECRET_KEY, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  'snapscan': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  'Thumbzup': {
    payment_gateway_fields: new Map([]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
  "vcs": {payment_gateway_fields: new Map([]),
    payment_gateway_config_fields: new Map([])},
  'zapper': {
    payment_gateway_fields: new Map([
      [paymentGatewayFields.API_URL, {}],
      [paymentGatewayFields.REFUND_URL, {}]
    ]),
    payment_gateway_config_fields: new Map([
      [configFields.UNIQUE_KEY, {}],
      [configFields.CUSTOMER, {}],
      [configFields.CUSTOMER_ID, {}],
      [configFields.PAYMENT_GATEWAY, {}],
      [configFields.MERCHANT_ID, {
        required: true,
      }],
      [configFields.SITE_ID, {
        required: true,
      }],
      [configFields.SECRET_KEY, {
        required: true,
      }],
      [configFields.SKIP_SUCCESS_PAGE, {
        required: true,
      }],
      [configFields.SKIP_ERROR_PAGE, {
        required: true,
      }],
      [configFields.DISPLAY_NAME, {}],
      [configFields.NOTE, {}]
    ]),
  },
})

export default gatewayFields;