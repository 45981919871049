/*
 * Created by Paul Engelke on 22 February 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  TableColumnBuilder,
  TableSortDirection,
  VirtualTable
} from '@hti-ui/react-web-material';
import {IconButton} from "@material-ui/core";
import {DeleteOutlineRounded as DeleteIcon} from '@material-ui/icons';
import {deleteCustomer} from "../../actions/customerActions";
import useActions from "../../hooks/useActions";
import useActionConfirmation from "../../hooks/useActionConfirmation";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A table for listing customers that can be managed.
 */
const Table = props => {

  const {
    disabled, searchTerm,
    customer, customers, onCustomerSelected,
  } = props;

  const [_deleteCustomer] = useActions([deleteCustomer]);
  const [confirmAction, confirmationDialog] = useActionConfirmation();
  const {dispatchSuccessMessage, handleError} = useGlobalMessenger();

  const [columns, setColumns] = React.useState([]);
  React.useEffect(() => {
    setColumns([

      new TableColumnBuilder()
      .id('name').label('Name')
      .sortable().sortDirection(TableSortDirection.ASC)
      .searchable()
      .build(),

      TableColumnBuilder.ActionColumn().build(),

    ]);
  }, []);

  /**
   * Checks if a customer in the table is the selected customer.
   */
  const isSelectedCustomer = React.useCallback((other) => (
      customer?.id === other.id
  ), [customer]);

  /**
   * Deletes a customer with user confirmation.
   */
  const onDeleteCustomerClicked = React.useCallback((customer) => {
    confirmAction(() => {

      _deleteCustomer({id: customer?.id})
      .then(() => {
        dispatchSuccessMessage('The customer was deleted.');
      })
      .catch(e => {
        handleError(e, 'The customer could not be deleted.');
      });

    }, {
      title: 'Delete Customer?',
      message: `Are you sure you want to delete '${customer.name}'?`,
      confirmLabel: 'Delete',
    });
  }, [_deleteCustomer,
    confirmAction, dispatchSuccessMessage, handleError,
  ]);

  /**
   * Action definitions for each row in the table.
   */
  const actions = React.useMemo(() => ([
    {
      Component: IconButton,
      componentProps: {children: (<DeleteIcon/>)},
      onClick: onDeleteCustomerClicked,
      tooltip: 'Delete Customer?'
    }
  ]), [onDeleteCustomerClicked]);

  return (<React.Fragment>

    <VirtualTable
        searchText={searchTerm}
        columns={columns}
        onColumnLabelClicked={setColumns}
        actions={actions}
        data={customers}
        emptyListMessage={'No customers found...'}
        onRowSelected={onCustomerSelected}
        isSelectedRow={isSelectedCustomer}
        disabled={disabled}
    />

    {confirmationDialog}

  </React.Fragment>);

};

Table.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  customer: PropTypes.object,
  customers: PropTypes.array.isRequired,
  onCustomerSelected: PropTypes.func.isRequired,
};

export default Table;
