/*
 * Created by Paul Engelke on 22 February 2021.
 */

import AppStatusCode from "../../constants/codes/appStatusCodes";

/**
 * A standard error class for all app errors.
 */
export default class StdError extends Error {

  /**
   * The internal error instance.
   *
   * @type {Error}
   * @private
   */
  _error = null;

  /**
   * The error code.
   *
   * @type {null | string | number}
   * @private
   */
  _code = AppStatusCode.UNKNOWN;

  /**
   * A descriptive message for the error.
   *
   * @type {null | string}
   * @private
   */
  _message = null;

  /**
   * Creates a new {@link StdError} instance.
   *
   * @param {string} [message] A descriptive message for the error.
   * @param {Error} [error] An error that has occurred.
   */
  constructor(message, error) {

    super();

    if (error instanceof StdError) {
      this._error = error.getError();
      this._message = error.getMessage();
      this._code = error.getCode();
    } else {
      this._error = error;
      this._message = message ?? error?.message;
    }
  }

  /**
   * Gets the internal error object.
   *
   * @return {Error}
   */
  getError() {
    return this._error;
  }

  /**
   * Gets the error code.
   *
   * @return {string|number}
   */
  getCode() {
    return this._code;
  }

  /**
   * Gets the error message.
   *
   * @return {string}
   */
  getMessage() {
    return this._message;
  }

  /**
   * Sets the error.
   *
   * @param {Error} e The internal error.
   * @protected
   */
  setError(e) {
    this._error = e;
  }

  /**
   * Sets the error code.
   *
   * @param {string|number} code The error code.
   * @return {StdError}
   */
  setCode(code) {
    this._code = code;
    return this;
  }

  /**
   * Sets the error message.
   *
   * @param {string} message The error message.
   * @return {StdError}
   */
  setMessage(message) {
    this._message = message;
    return this;
  }

}
