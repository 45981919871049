import React from 'react';
import {useSelector} from "react-redux";
import {StateUtility} from "@hti-ui/redux-core";
import {
  LoadingIndicator,
  Page,
  PageContent,
  PageToolbar, SearchField,
  Select,
} from "@hti-ui/react-web-material";
import Drawer from "./Drawer";
import Table from "./Table";
import {Button, Grid, IconButton} from "@material-ui/core";
import useActions from "../../hooks/useActions";
import {
  fetchPaymentGatewayConfigs
} from "../../actions/paymentGatewayConfigActions";
import {
  fetchProductNotificationErrors
} from "../../actions/productNotifyErrorActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

const ProductNotifyErrorPage = () => {

  const {
    loading,
    productNotifyErrors,
    paymentGatewayConfigs,
  } = useSelector(state => ({
    loading: StateUtility.isBusy(state.paymentGatewayConfigs),
    productNotifyErrors: state.productNotifyErrors?.data,
    paymentGatewayConfigs: state.paymentGatewayConfigs?.data,
  }));

  const [_fetchPaymentGatewayConfigs, _fetchProductNotificationErrors] = useActions(
      [fetchPaymentGatewayConfigs, fetchProductNotificationErrors]);
  const {handleError} = useGlobalMessenger();

  const [showDrawer, toggleDrawer] = React.useState(false);
  const [paymentGatewayConfig, setPaymentGatewayConfig] = React.useState(null);
  const [productNotifyError, setProductNotifyError] = React.useState(null);

  const [searchTerm, setSearchTerm] = React.useState('');
  const trimmedSearchTerm = searchTerm.trim();

  /**
   * Fetches all the data required by this page and its subcomponents.
   */
  const fetchData = React.useCallback((force = false) => {
    Promise.all([
      _fetchPaymentGatewayConfigs({force}),
    ])
    .catch(e => {
      handleError(e, 'An error occurred while fetching data.');
    });

  }, [_fetchPaymentGatewayConfigs, handleError]);

  /**
   * Fetches all the data required by this page and its subcomponents.
   */
  const onSearchClicked = React.useCallback((force = false) => {
    console.log("search for...");
    _fetchProductNotificationErrors(
        {"paymentGatewayConfigId": paymentGatewayConfig})
    .catch(e => {
      handleError(e, 'An error occurred while searching data.');
    });
  }, [_fetchPaymentGatewayConfigs, handleError, paymentGatewayConfig]);

  React.useEffect(() => fetchData(false), [fetchData]);

  /**
   * Sets the selected product notif error and shows the drawer to resend.
   */
  const onProductNotifyErrorSelected = React.useCallback(
      (productNotifyError) => {
        setProductNotifyError(productNotifyError);
        toggleDrawer(true);
      }, []);
  const [paymentGatewayConfigOptions, setPaymentGatewayConfigOptions] = React.useState(
      [])

  React.useEffect(() =>
      setPaymentGatewayConfigOptions(
          paymentGatewayConfigs?.map(({uniqueKey, id}) => ({
            label: uniqueKey,
            value: id,
          }))), [paymentGatewayConfigs]);

  /**
   * Hides the drawer and clears the selected PaymentGatewayConfig.
   */
  const onDrawerDismissed = React.useCallback((refresh) => {
    setProductNotifyError(null);
    toggleDrawer(false);
    if (refresh) {
      onSearchClicked();
    }
  }, []);

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <PageToolbar
        title={'Product Notification Errors'}
        subtitle={'View and resend payment notifications to the calling HTI system.'}
    >


      <SearchField
          placeholder={'Filter...'}
          value={searchTerm}
          onChange={setSearchTerm}
          disabled={loading}
      />



    </PageToolbar>

    <PageContent>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
              label={"PaymentGatewaySetup"}
              value={paymentGatewayConfig}
              options={paymentGatewayConfigOptions}
              onChange={setPaymentGatewayConfig}
              fullWidth
          />
        </Grid>
        <Grid item xl={6}>
          <Button
              variant={'contained'}
              color={'secondary'}
              onClick={onSearchClicked}
              disabled={loading}
          >Search</Button>
        </Grid>
      </Grid>
      <Table
          productNotifyError={productNotifyError}
          productNotifyErrors={productNotifyErrors}
          onProductNotifyErrorSelected={onProductNotifyErrorSelected}
          disabled={loading}
          searchTerm={trimmedSearchTerm}
      />

      <Drawer
          visible={showDrawer}
          disabled={loading}
          dismiss={onDrawerDismissed}
          productNotifyError={productNotifyError}
      />

    </PageContent>

  </Page>);

};

export default ProductNotifyErrorPage;
