import * as actionType from '../actions/ActionType';
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const initialState = {loggedIn: false, login: {}};

export default (state = initialState, action) => {
  switch (action.type) {

    case actionType.LOGIN_SUCCESS: {
      const {id, uid, name} = action.login;
      return {
        ...state,
        loggedIn: true,
        processing: false,
        login: {
          ...state.login,
          id,
          uid,
          name
        }
      };
    }
    case actionType.LOGIN_FAILURE:
      return {
        ...state,
        processing: false,
        error: action.error
      };

    case GlobalActionTypes.RESET:
      return initialState;

    default:
      return state
  }
};
