/*
 * Created by Andre Richards on 3 March 2021.
 */

import {ActionTypeUtility} from "@hti-ui/redux-core";

/**
 * A set of action types for managing global Customer redux state.
 */
const CustomerActionTypes = ActionTypeUtility.createActionTypes('CUSTOMER', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default CustomerActionTypes;
