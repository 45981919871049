/*
 * Created by Paul Engelke on 23 February 2021.
 */

import React from "react";
import Slide from "@material-ui/core/Slide";

const SlideUpTransition = (props, ref) =>
    (<Slide ref={ref} direction={'up'} {...props}/>);

export default React.forwardRef(SlideUpTransition);
