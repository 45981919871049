/*
 * Created by Paul Engelke on 22 February 2021.
 */

import React from 'react';
import {useSelector} from "react-redux";
import {StateUtility} from "@hti-ui/redux-core";
import {
  LoadingIndicator,
  Page,
  PageContent,
  PageToolbar,
  SearchField,
} from "@hti-ui/react-web-material";
import Drawer from "./Drawer";
import Table from "./Table";
import {Button, Grid, IconButton} from "@material-ui/core";
import {RefreshRounded as RefreshIcon} from '@material-ui/icons';
import useActions from "../../hooks/useActions";
import {fetchCustomers} from "../../actions/customerActions";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A page for managing customers.
 */
const CustomerPage = () => {

  const {loading, customers} = useSelector(state => ({
    loading: StateUtility.isBusy(state.customers),
    customers: state.customers.data,
  }));

  const [_fetchCustomers] = useActions([fetchCustomers]);
  const {handleError} = useGlobalMessenger();

  const [showDrawer, toggleDrawer] = React.useState(false);
  const [customer, setCustomer] = React.useState(null);

  const [searchTerm, setSearchTerm] = React.useState('');
  const trimmedSearchTerm = searchTerm.trim();

  /**
   * Fetches data for the page, i.e. a list of customers.
   */
  const fetchData = React.useCallback((force = true) => {
    _fetchCustomers({force})
    .catch(e => void handleError(e, 'Unable to fetch a list of customers.'));
  }, [_fetchCustomers, handleError]);

  React.useEffect(() => fetchData(false), [fetchData]);

  /**
   * Ensures that the selected customer is null and shows the drawer so that a
   * new customer can be created.
   */
  const onCreateClicked = React.useCallback(() => {
    setCustomer(null);
    toggleDrawer(true);
  }, []);

  /**
   * Sets the selected customer and shows the drawer to update the customer.
   */
  const onCustomerSelected = React.useCallback((customer) => {
    setCustomer(customer);
    toggleDrawer(true);
  }, []);

  /**
   * Hides the drawer and clears the selected customer.
   */
  const onDrawerDismissed = React.useCallback(() => {
    setCustomer(null);
    toggleDrawer(false);
  }, []);

  return (<Page>

    <LoadingIndicator visible={loading}/>

    <Grid container spacing={1}>
      <Grid item xs={12}>

      </Grid>
    </Grid>

    <PageToolbar
        title={'Customers'}
        subtitle={'View and manage customers.'}
    >

      <SearchField
          placeholder={'Find...'}
          value={searchTerm}
          onChange={setSearchTerm}
          disabled={loading}
      />

      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={onCreateClicked}
          disabled={loading}
      >Create</Button>

      <IconButton onClick={fetchData} disabled={loading}>
        <RefreshIcon/>
      </IconButton>

    </PageToolbar>

    <PageContent>

      <Table
          customer={customer}
          customers={customers}
          onCustomerSelected={onCustomerSelected}
          searchTerm={trimmedSearchTerm}
          disabled={loading}
      />

      <Drawer
          visible={showDrawer}
          disabled={loading}
          dismiss={onDrawerDismissed}
          customer={customer}
      />

    </PageContent>

  </Page>);

};

export default CustomerPage;
