const paymentGatewayFields = {
  NAME: "Name",
  REDIRECT_URL : "Redirect URL",
  INITIATE_URL : "Initiate URL",
  API_URL : "API URL",
  QUERY_URL:"Query URL",
  REFUND_URL : "Refund URL",
  IS_ECOMMERCE :"Has an e-commerce site",
  DISPLAY_NAME : "Display Name"
}

export default paymentGatewayFields;