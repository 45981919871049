/*
 * Created by Paul Engelke on 23 February 2021.
 */

import React from 'react';
import ConfirmationDialog from "../components/ConfirmationDialog";

/**
 * A hook providing an action interceptor that will prompt the user for
 * confirmation before allowing the action to be executed.
 * <p>
 * This hook returns an array with the following entities in order of index
 * (starting at 0):
 *
 * <ol>
 *   <li>
 *     <pre>
 *     confirmAction: function(
 *      action: function,
 *      options?: {
 *        title?: string,
 *        message?: string,
 *        confirmLabel?: string,
 *        cancelLabel?: string
 *      }
 *     )
 *     </pre>
 *     The action interceptor that trigger a prompt for user confirmation.
 *   </li>
 *   <li>
 *     Confirmation Dialog Component: The confirmation dialog component that
 *     you will need to mount to your component. This will be managed
 *     internally by the hook and will provide the necessary UI for user
 *     confirmation. The text details of the dialog can be altered via the
 *     <code>confirmAction</code> function exposed at index 0.
 *   </li>
 * </ol>
 *
 * @return {*[]}
 */
const useActionConfirmation = () => {

  const [showDialog, toggleDialog] = React.useState(false);
  const [title, setTitle] = React.useState();
  const [message, setMessage] = React.useState();
  const [confirmLabel, setConfirmLabel] = React.useState();
  const [cancelLabel, setCancelLabel] = React.useState();
  const actionRef = React.useRef(() => undefined);

  /**
   * The action interceptor.
   */
  const confirmAction = React.useCallback((action, options) => {
    const {title, message, confirmLabel, cancelLabel} = options || {};
    actionRef.current = action;
    setTitle(title);
    setMessage(message);
    setConfirmLabel(confirmLabel);
    setCancelLabel(cancelLabel);
    toggleDialog(true);
  }, []);

  /**
   * Hides the confirmation dialog and triggers the current action.
   */
  const onConfirm = React.useCallback(() => {
    actionRef.current();
    toggleDialog(false);
  }, []);

  /**
   * Hides the confirmation dialog and does clears the current action, without
   * triggering it.
   */
  const onCancel = React.useCallback(() => {
    actionRef.current = () => undefined;
    toggleDialog(false);
  }, []);

  /**
   * The UI component for user confirmation.
   */
  const dialog = (<ConfirmationDialog
      open={showDialog}
      title={title || 'Confirm Action'}
      message={message || 'Are you sure you want to do this?'}
      onConfirm={onConfirm}
      confirmButtonText={confirmLabel || "Confirm"}
      onAbort={onCancel}
      abortButtonText={cancelLabel || "Cancel"}
  />);

  return [confirmAction, dialog];

};

export default useActionConfirmation;

