/*
 * Created by Paul Engelke on 22 February 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
    SideDrawer,
    SideDrawerContent,
    SideDrawerToolbar,
    TextField,
} from "@hti-ui/react-web-material";
import {Button, Grid} from "@material-ui/core";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import useActions from "../../hooks/useActions";
import {createCustomer, updateCustomer} from "../../actions/customerActions";
import {v4 as uuidv4} from 'uuid'


/**
 * A drawer for creating and updating customer entities.
 */
const Drawer = props => {

    const {visible, disabled, dismiss, customer} = props;
    const isNewCustomer = !customer;
    const customerId = customer?.id;
    const apiKey = customer?.apiKey || '';

    const {dispatchSuccessMessage, handleError} = useGlobalMessenger();
    const [
        _createCustomer,
        _updateCustomer,
    ] = useActions([
        createCustomer,
        updateCustomer,
    ]);

    const [name, setName] = React.useState('');
    const [logoUrl, setLogoUrl] = React.useState('');
    const [confirmationText, setConfirmationText] = React.useState('');
    React.useEffect(() => {
        setName(customer?.name ?? '');
        setLogoUrl(customer?.logoUrl ?? '');
        setConfirmationText(customer?.confirmationText ?? '');
    }, [visible, customer]);

    const canCommit = !!name?.trim();

    /**
     * Creates a new customer. If successful, the drawer is dismissed.
     * The user is notified of the success or failure of the request.
     */
    const onCreateClicked = React.useCallback(() => {
        let key = uuidv4();
        console.log(key);
        _createCustomer({name, apiKey: key, logoUrl, confirmationText})
            .then(() => {
                dispatchSuccessMessage('The customer has been created.');
                dismiss();
            })
            .catch(e => void handleError(e, 'The customer could not be created.'));

    }, [
        name,
        _createCustomer,
        dispatchSuccessMessage, handleError,
        dismiss, confirmationText, logoUrl
    ]);

    /**
     * Updates an existing customer. If successful, the drawer is dismissed.
     * The user is notified of the success or failure of the request.
     */
    const onUpdateClicked = React.useCallback(() => {

        let data =
            {
                id: customerId,
                name,
                apiKey,
                logoUrl,
                confirmationText
            }
        if (apiKey === '') {
            data = {...data, apiKey: uuidv4()}
        }
        _updateCustomer(data)
            .then(() => {
                dispatchSuccessMessage('The customer has been updated.');
                dismiss();
            })
            .catch(e => void handleError(e, 'The customer could not be updated.'));

    }, [
        customerId, name,
        _updateCustomer, dispatchSuccessMessage, handleError,
        dismiss, apiKey, logoUrl, confirmationText
    ]);

    return (<SideDrawer visible={visible} md={4} sm={6} xs={12}>

        <SideDrawerToolbar
            title={isNewCustomer
                ? 'New Customer'
                : 'Updating Customer'}
        >

            <Button
                variant={'outlined'}
                onClick={dismiss}
                disabled={disabled}
            >Cancel</Button>

            <Button
                variant={'contained'}
                color={'secondary'}
                onClick={isNewCustomer ? onCreateClicked : onUpdateClicked}
                disabled={disabled || !canCommit}
            >{isNewCustomer ? 'Create' : 'Update'}</Button>

        </SideDrawerToolbar>

        <SideDrawerContent>

            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <TextField
                        label={'Name'}
                        value={name}
                        onChange={setName}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={'ID'}
                        value={customerId || 0}
                        onChange={() => {
                        }}
                        fullWidth
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={'Api Key'}
                        value={apiKey || ''}
                        onChange={() => {
                        }}
                        fullWidth
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label={'Logo Url'}
                        value={logoUrl || ''}
                        onChange={setLogoUrl}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        multiline={true}
                        label={'Confirmation Text'}
                        value={confirmationText || ''}
                        onChange={setConfirmationText}
                        fullWidth
                    />
                </Grid>

            </Grid>

        </SideDrawerContent>

    </SideDrawer>);

};

Drawer.propTypes = {
    visible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
    customer: PropTypes.object,
};

export default Drawer;
