/*
 * Created by Paul Engelke on 22 February 2021.
 */

import {useHistory} from "react-router-dom";
import useActions from "./useActions";
import {
  dispatchErrorMessage,
  dispatchInfoMessage,
  dispatchSuccessMessage,
  dispatchWarningMessage
} from "../actions/globalMessageActions";
import {useCallback} from 'react';
import StdError from "../util/error/stdError";
import HttpStatusCode from "../constants/codes/httpStatusCodes";
import AppStatusCode from "../constants/codes/appStatusCodes";
import RouteNames from "../constants/routeNames";
import {signOut} from "../actions/authActions";

/**
 * A hook that provides easy access to the global message action creators,
 * as well as a useful error handler.
 *
 * @return {{
 *  handleError: (function(*, string|function(*)): void),
 *  dispatchInfoMessage: function(string): void,
 *  dispatchWarningMessage:function(string): void,
 *  dispatchSuccessMessage: function(string): void,
 *  dispatchErrorMessage: function(string): void,
 * }}
 */
const useGlobalMessenger = () => {

  const history = useHistory();

  const [
    dispatchSuccess,
    dispatchInfo,
    dispatchWarning,
    dispatchError,
    _signOut,
  ] = useActions([
    dispatchSuccessMessage,
    dispatchInfoMessage,
    dispatchWarningMessage,
    dispatchErrorMessage,
    signOut,
  ]);

  /**
   * Handles an error by considering some standard error cases. If no standard
   * case is met, the fallback is used.
   *
   * @type {function(*=, *=): void}
   */
  const handleError = useCallback((e, fallback) => {

    if (!(e instanceof StdError)) {
      e = new StdError(undefined, e);
    }

    let errorCode = e.getCode();

    switch (errorCode) {

      case HttpStatusCode.UNAUTHORIZED:
        _signOut();
        history.push(RouteNames.LoginPage);
        break;

      case HttpStatusCode.FORBIDDEN:
        dispatchError('Your request was forbidden.');
        break;

      case AppStatusCode.CONNECTION_TIMEOUT:
        dispatchError(
            'The request timed out. This may be due to a slow connection. '
            + 'Please try again.'
        );
        break;

      case AppStatusCode.NETWORK_ERROR:
        dispatchError(
            'A network error occurred. Please check your connection.');
        break;

      default: {
        if (typeof fallback === 'string') {
          dispatchError(fallback);
        } else if (typeof fallback === 'function') {
          fallback(e);
        }
        break;
      }

    }

  }, [_signOut, dispatchError, history,]);

  return {
    dispatchSuccessMessage: dispatchSuccess,
    dispatchInfoMessage: dispatchInfo,
    dispatchWarningMessage: dispatchWarning,
    dispatchErrorMessage: dispatchError,
    handleError,
  };

};

export default useGlobalMessenger;
