import {ActionTypeUtility} from "@hti-ui/redux-core";


const ProductNotifyErrorActionTypes = ActionTypeUtility.createActionTypes('ProductNotifyError', {
  REQUEST: 'REQUEST',
  FAIL_REQUEST: 'FAIL_REQUEST',
  SET: 'SET',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
});

export default ProductNotifyErrorActionTypes;
