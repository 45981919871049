/*
 * Created by Andre Richards on 3 March 2021.
 */

import {
  LocalCacheRegistry,
  LocalCache,
  CacheLifetime
} from '@hti-ui/local-cache';
import HttpManager from "../util/httpManager";
import PaymentGatewayActionTypes from "../constants/action-types/paymentGatewayActionTypes";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * The modelled entity on the server, for which all operations below are
 * performed.
 * @type {string}
 */
const entity = 'paymentGateway';

/**
 * Fetches a list of PaymentGateways.
 *
 * @param {Object} args The function arguments, including request parameters.
 * @param {boolean} [args.force=false] Should local cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchPaymentGateways = (args) => (dispatch) => {

  const {force = false} = args;
  const key = 'all-paymentGateways';

  if (!force && cache.has(key)) {
    return cache.get(key);
  }

  dispatch({type: PaymentGatewayActionTypes.REQUEST});
  const request = HttpManager.get({entity});

  cache.clear();
  cache.set(key, CacheLifetime.SHORT, request);

  return request
  .then(r => {
    dispatch({type: PaymentGatewayActionTypes.SET, data: r?.data?.data ?? []});
    return r;
  })
  .catch(e => {
    cache.delete(key);
    dispatch({type: PaymentGatewayActionTypes.FAIL_REQUEST});
    throw e;
  })

};

/**
 * Creates a new PaymentGateway.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.name The new PaymentGateway's name.
 * @param {string} args.redirectUrl The new PaymentGateway's redirectUrl.
 * @param {string} args.apiUrl The new PaymentGateway's apiUrl.
 * @param {string} args.queryUrl The new PaymentGateway's queryUrl.
 * @return {function(*,*): Promise}
 */
export const createPaymentGateway = (args) => (dispatch) => {
  const {name, redirectUrl, apiUrl, queryUrl} = args;
  dispatch({type: PaymentGatewayActionTypes.REQUEST});
  return HttpManager.post({entity, payload: {name, redirectUrl, apiUrl, queryUrl}})
  .then(r => {
    dispatch({type: PaymentGatewayActionTypes.CREATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing PaymentGateway.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.name The PaymentGateway's new name.
 * @param {string} args.redirectUrl The PaymentGateway's new redirectUrl.
 * @param {string} args.apiUrl The PaymentGateway's new apiUrl.
 * @param {string} args.queryUrl The PaymentGateway's new queryUrl.
 * @return {function(*,*): Promise}
 */
export const updatePaymentGateway = (args) => (dispatch) => {
  const {id, name, redirectUrl, apiUrl, queryUrl, displayName, initiateUrl} = args;
  dispatch({type: PaymentGatewayActionTypes.REQUEST});
  return HttpManager.put({entity, payload: {id, name, redirectUrl, apiUrl, queryUrl, displayName, initiateUrl}})
  .then(r => {
    dispatch({type: PaymentGatewayActionTypes.UPDATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes an existing PaymentGateway.
 *
 * @param {Object} args The request parameters.
 * @param {number} args.id The PaymentGateway's ID.
 * @return {function(*,*): Promise}
 */
export const deletePaymentGateway = (args) => (dispatch) => {
  const {id} = args;
  dispatch({type: PaymentGatewayActionTypes.REQUEST});
  return HttpManager.delete({entity, payload: {id}})
  .then(r => {
    dispatch({type: PaymentGatewayActionTypes.DELETE, data: {id}});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayActionTypes.FAIL_REQUEST});
    throw e;
  });
};
