/* 
 * Created by Paul Engelke on 21 May 2019.
 */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Redirect, Switch} from 'react-router-dom';
import RouteNames from "../constants/routeNames";
import DashboardPage from "./DashboardPage";
import CustomerPage from "./CustomerPage";
import PaymentGatewayPage from "./PaymentGatewayPage";
import PaymentGatewayConfigPage from "./PaymentGatewayConfigPage";
import ProductNotificationErrorPage from "./ProductNotifyErrorPage";
import NavigationBar from "./NavigationBar";
import PrivateRoute from "./PrivateRoute";
import GlobalSnackbar from "./GlobalSnackbar";

/**
 * The app's base component responsible for dictating the layout of it's
 * primary components, i.e. the navigation bar and viewable content area.
 *
 * It is also responsible for defining navigable routes.
 */
const App = ({classes}) => {

  return (<div className={classes.container}>

    <NavigationBar/>

    <div className={classes.content}>
      <Switch>
        <PrivateRoute exact path={RouteNames.DashboardPage}
                      component={DashboardPage}/>
        <PrivateRoute exact path={RouteNames.CustomerPage}
                      component={CustomerPage}/>
        <PrivateRoute exact path={RouteNames.PaymentGatewayPage}
                      component={PaymentGatewayPage}/>
        <PrivateRoute exact path={RouteNames.PaymentGatewayConfigPage}
                      component={PaymentGatewayConfigPage}/>
        <PrivateRoute exact path={RouteNames.ProductNotificationErrorPage}
                      component={ProductNotificationErrorPage}/>
        <Redirect to={RouteNames.DashboardPage}/>
      </Switch>
    </div>

    <GlobalSnackbar/>

  </div>);

};

const styles = {
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
};

export default withStyles(styles)(App);
