/*
 * Created by Andre Richards on 3 March 2021.
 */
import {StateUtility} from "@hti-ui/redux-core";
import PaymentGatewayActionTypes from "../constants/action-types/paymentGatewayActionTypes";
import GlobalActionTypes from "../constants/action-types/globalActionTypes";

const DEFAULT_STATE = StateUtility.State({data: []}, {async: true});

/**
 * A state reducer for managing a global list of PaymentGateways.
 */
export default (state = DEFAULT_STATE, action) => {

  // console.log('paymentGatewayReducer: ' + JSON.stringify(action));

  switch (action.type) {

    case PaymentGatewayActionTypes.REQUEST:
      return StateUtility.defaultRequest(state);

    case PaymentGatewayActionTypes.FAIL_REQUEST:
      return StateUtility.defaultFailRequest(state);

    case PaymentGatewayActionTypes.SET:
      return StateUtility.defaultSet(state, action.data);

    case PaymentGatewayActionTypes.CREATE:
      return create(state, action);

    case PaymentGatewayActionTypes.UPDATE:
      return update(state, action);

    case PaymentGatewayActionTypes.DELETE:
      return _delete(state, action);

    case GlobalActionTypes.RESET:
      return DEFAULT_STATE;

    default:
      return state;

  }

};

const create = (state, action) => {
  const next = [...state.data, action.data];
  return StateUtility.defaultSet(state, next);
};

const update = (state, action) => {
  const next = state.data.map(c => (
      c.id === action.data.id
          ? action.data
          : c
  ));
  return StateUtility.defaultSet(state, next);
};

const _delete = (state, action) => {
  const next = state.data.filter(c => c.id !== action.data.id);
  return StateUtility.defaultSet(state, next);
};
